<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
            <el-breadcrumb-item>酒店设施</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称/编码：</label>
                  <el-input class="width-220" v-model="condition" :placeholder="$t('msg.room_name_code')"  clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary"  @click="handleAdd" ><span v-text="$t('msg.add_facilities')">新建设施</span></el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  style="width: 100%"
                  :stripe="true"
                  border fit
                  >
            <el-table-column :label="$t('msg.number')" width="60">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column :label="$t('msg.room_icon')" width="70">
               <template slot-scope="scope">
                  <img v-if="scope.row.icon" :src="scope.row.icon" width="40" height="40" class="v-align-middle"/>
                  <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="v-align-middle" alt="">
               </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('msg.facilities_name')" min-width="110"></el-table-column>
            <el-table-column prop="code" :label="$t('msg.facilities_code')" min-width="110"></el-table-column>
            <el-table-column prop="createTime" :label="$t('msg.add_time')" min-width="150" sortable></el-table-column>
            <el-table-column prop="remark" :label="$t('msg.remark')" min-width="180"></el-table-column>
            <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
               <template slot-scope="scope">
                  <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                  <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                  </el-button>
                  <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                  <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                  </el-button>
               </template>
            </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
         </div>
         <!-- 新增/编辑设施 -->
         <el-dialog :title="$t('msg.edit_facilities')" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item style="width:100%" label="图标">
                  <template>
                     <el-radio v-model="radioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                     <el-radio v-model="radioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
                  </template>
               </el-form-item>
               <el-form-item>
                  <el-input  class="width-300" v-if="radioIcon === '1'" v-model="icon" :placeholder="$t('msg.font_icon')" clearable></el-input>
                  <div v-else class="cate-upload">
                     <el-upload
                              class="upload-demo"
                              :limit="1"
                              drag
                              accept="image/png"
                              action="#"
                              :auto-upload="false"
                              :on-change="handleChange">
                        <p style="float: left;line-height: 31px">上传图标</p>
                        <i class="el-icon-upload"></i>
                     </el-upload>
                     <div class="old-icon" v-if="oldIconShow">
                        <p><i class="el-icon-document"></i>{{oldIcon}}</p>
                        <span @click="oldIconShow = false" class="el-icon-close"></span>
                     </div>
                  </div>
               </el-form-item>
               <el-form-item required label="设施名称">
                  <el-input  class="width-300" v-model="name" :placeholder="$t('msg.facilities_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="设施编码">
                  <el-input  class="width-300" v-model="code" :placeholder="$t('msg.facilities_code')" clearable></el-input>
               </el-form-item>
               <el-form-item label="备注信息">
                  <el-input class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="handleReset()" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>

   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data () {
         return {
            tableData: [],        // 表格数据
            total: 0,             // 表格数据总数
            loading: true,        // 动画加载效果
            oldIcon: '',          // 旧图标
            oldIconShow: false,   // 旧图标隐藏/显示
            isShow: false,        // 弹窗是否显示
            action: '',           // 当前操作行为
            operWidth: 0,         // 编辑宽度样式
            radioIcon: '1',       // 图标/图片切换
            /* 查询列表字段 */
            limit: 0,             // 每页显示数
            page: 1,              // 当前第几页
            condition: '',        // 名称/编码
            /* 提交表单字段 */
            id: '',               // 设施ID
            icon: '',             // 图标
            name: '',             // 名称
            code: '',             // 编码
            remark: '',           // 备注
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            facilitys_name_no_null: '设施名称不能为空！',
            facilitys_code_no_null: '设施编码不能为空！',
         }
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getFacilities()
      },
      methods: {
         // 设施列表
         getFacilities(){
            let url = urlObj.getFacilityPages
            let param = {
               limit: this.limit,
               page: this.page,
               condition: this.condition
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => item.icon = item.icon && window.getResourcesHost() + item.icon)
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 处理搜索
         handleQuery(bool){
            if (bool) return this.getFacilities()
            this.condition = ''
         },
         // 改变每页显示数
         pageChange(num) {
            this.limit = num
            this.getFacilities()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getFacilities()
         },
         // 获取上传文件资源
         handleChange(file){
            this.file = file.raw
         },
         // 添加设施
         handleAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑设施
         handleEdit(row){
            this.isShow = true
            this.action = 'edit'
            // 数据回显
            this.id = row.id
            this.name = row.name
            this.code = row.code
            this.remark = row.remark
            if (row.icon.substr(-3) === 'png'){
               this.radioIcon = '2'
               this.oldIconShow = true
               const arr = row.icon.split('/')
               this.oldIcon = arr[arr.length-1]
            }
         },
         // 重置表单
         handleReset(){
            this.file = null
            this.oldIcon = ''
            this.oldIconShow = false
            this.radioIcon = '1'
            this.iconfont = ''
            this.name = ''
            this.code = ''
            this.remark = ''
         },
         // 保存设施
         handleSave(){
            // 表单验证
            if (!this.name) {
               this.$alert(this.facilitys_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.code) {
               this.$alert(this.facilitys_code_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addFacility
            let param = new FormData()
            if (this.file){
               param.append('file', this.file)             // 文件
               param.append('module', 'hotel')       // 所在模块
               param.append('menu', 'facility')      // 所属菜单
               param.append('func', 'icon')          // 图片功能
               param.append('category', 'icon')      // 图片分类
               param.append('isThumb', true)         // 是否开启缩略图
            }
            // param.append('icon', this.dUploadIcon)
            param.append('name', this.name)
            param.append('code', this.code)
            param.append('remark', this.remark)
            if (this.action === 'edit'){
               url = urlObj.editFacility
               param.append('id', this.id)
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.handleClose()
                  this.getFacilities()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭
         handleClose(){
            this.isShow = false
            this.handleReset()
         },
         // 删除
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deleteFacility + `/${id}`
               this.$axios.get(url, {}).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getFacilities()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
      }
   }
</script>
<style lang="scss">
   .cate-upload{
      position: relative;
   }
   .cate-upload .upload-demo{
      height: 32px;
   }
   .cate-upload .el-upload-dragger{
      width: 300px;
      height: 32px;
      text-align: right;
      border: solid 1px #DCDFE6;
      padding: 0 5px 0 16px;
      font-size: 13px;
      border-radius: 4px;
      color: #cccccc;
   }
   .cate-upload .el-upload-dragger .el-icon-upload{
      line-height: 29px;
      margin: 0;
      font-size: 16px;
   }
   .cate-upload .old-icon{
      top: 0;
      position: absolute;
      width: 260px;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      padding: 0 4px;
      background: #f5f7fa;
      box-sizing: border-box;
   }
   .cate-upload .old-icon p{
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 32px;
      text-overflow:ellipsis;
   }
   .cate-upload .old-icon p i{
      margin: 0 4px 0 0;
   }
   .cate-upload .old-icon span{
      line-height: 32px;
   }
   .cate-upload .el-upload-list{
      position: absolute;
      top: 0;
      width: 260px;
      background: #f5f7fa;
   }
   .cate-upload .el-upload-list li{
      margin-top: 0!important;
      line-height: 32px;
   }
   .cate-upload .el-upload-list__item .el-icon-close{
      top: 9px;
   }
</style>
